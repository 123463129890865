import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';

const sidebarConfigOpenData = [
  {
    subheader: 'Open Data',
    items: [
      {
        title: 'Relatórios de vendas',
        path: PATH_DASHBOARD.general.opendata,
        icon: <AddchartRoundedIcon />
      },
      {
        title: 'Clientes',
        path: PATH_DASHBOARD.general.dataclientregister,
        icon: <GroupRoundedIcon />
      }
    ]
  }
];

export default sidebarConfigOpenData;
