import { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <AuthGuard>
              <Register />
            </AuthGuard>
          )
        },
        {
          path: 'open-data-register',
          element: <OpenDataRegister />
        },
        {
          path: 'open-data-register-cielo-final',
          element: <OpenDataRegisterCieloFinal />
        },
        {
          path: 'external-origination-detail',
          element: <ExternalOriginationDetail />
        },
        {
          path: 'external-client-origination-register',
          element: <ExternalClientOriginationRegister />
        },
        {
          path: 'external-client-origination-register-value',
          element: <ExternalClientOriginationRegisterValue />
        },
        {
          path: 'external-client-origination-register-owner',
          element: <ExternalClientOriginationRegisterOwner />
        },
        {
          path: 'external-client-origination-register-company',
          element: <ExternalClientOriginationRegisterCompany />
        },
        {
          path: 'external-client-origination-register-opendata',
          element: <ExternalClientOriginationRegisterOpenData />
        },
        {
          path: 'external-client-origination-register-confirmation',
          element: <ExternalClientOriginationRegisterConfirmation />
        },
        {
          path: 'external-client-origination-register-final',
          element: <ExternalClientOriginationRegisterFinal />
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> }
        // { path: 'change-password', element: <ChangePassword /> }
        // { path: 'verify', element: <VerifyCode /> }
      ]
    },
    {
      path: 'cotacoes',
      children: [
        {
          path: 'valor',
          element: <LoanRegisterValue />
        },
        {
          path: 'socios',
          element: <LoanRegisterOwner />
        },
        {
          path: 'empresa',
          element: <LoanRegisterCompany />
        },
        {
          path: 'open-data',
          element: <LoanRegisterOpenData />
        },
        {
          path: 'confirmacao',
          element: <LoanRegisterConfirmation />
        },
        {
          path: 'final',
          element: <LoanRegisterFinal />
        }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/user/account" replace /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'analytics', element: <GeneralHistoric /> },
        { path: 'charge', element: <GeneralCharge /> },
        { path: 'collateralapproval', element: <GeneralApproval /> },
        { path: 'opslaunch', element: <OpsLaunch /> },
        { path: 'scheduleconsult', element: <ScheduleConsult /> },
        { path: 'launchfuture', element: <LaunchFuture /> },
        { path: 'clientRegister', element: <ClientRegister /> },
        { path: 'myKpis', element: <MyKpis /> },
        { path: 'openData', element: <OpenData /> },
        { path: 'dataclientregister', element: <OpenDataClientRegister /> },
        { path: 'originationclientregister', element: <OriginationClientRegister /> },
        { path: 'originationOperations', element: <OriginationOperations /> },
        { path: 'originationDetail', element: <OriginationDetail /> },
        { path: 'quoteoperations', element: <QuoteOperations /> },
        { path: 'ownOperations', element: <OwnOperations /> },
        { path: 'contractDetail', element: <ContractDetail /> },
        { path: 'myReceivables', element: <MyReceivables /> },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'account', element: <UserAccount /> }
          ]
        }
      ]
    },
    // Main Routes
    {
      path: '/',
      element: <Navigate to="/dashboard" replace />
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const OpenDataRegister = Loadable(lazy(() => import('../pages/authentication/OpenDataRegister')));
const OpenDataRegisterCieloFinal = Loadable(lazy(() => import('../pages/authentication/OpenDataRegisterCieloFinal')));
const ExternalOriginationDetail = Loadable(lazy(() => import('../pages/authentication/ExternalOriginationDetail')));
const ExternalClientOriginationRegister = Loadable(
  lazy(() => import('../pages/authentication/ExternalClientOriginationRegister'))
);
const ExternalClientOriginationRegisterValue = Loadable(
  lazy(() => import('../pages/authentication/ExternalClientOriginationRegisterValue'))
);
const ExternalClientOriginationRegisterOwner = Loadable(
  lazy(() => import('../pages/authentication/ExternalClientOriginationRegisterOwner'))
);
const ExternalClientOriginationRegisterCompany = Loadable(
  lazy(() => import('../pages/authentication/ExternalClientOriginationRegisterCompany'))
);
const ExternalClientOriginationRegisterOpenData = Loadable(
  lazy(() => import('../pages/authentication/ExternalClientOriginationRegisterOpenData'))
);
const ExternalClientOriginationRegisterConfirmation = Loadable(
  lazy(() => import('../pages/authentication/ExternalClientOriginationRegisterConfirmation'))
);
const ExternalClientOriginationRegisterFinal = Loadable(
  lazy(() => import('../pages/authentication/ExternalClientOriginationRegisterFinal'))
);
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
// const ChangePassword = Loadable(lazy(() => import('../pages/authentication/ChangePassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralHistoric = Loadable(lazy(() => import('../pages/dashboard/GeneralHistoric')));
const GeneralCharge = Loadable(lazy(() => import('../pages/dashboard/GeneralCharge')));
const GeneralApproval = Loadable(lazy(() => import('../pages/dashboard/GeneralApproval')));
const OpsLaunch = Loadable(lazy(() => import('../pages/dashboard/OpsLaunch')));
const ScheduleConsult = Loadable(lazy(() => import('../pages/dashboard/ScheduleConsult')));
const LaunchFuture = Loadable(lazy(() => import('../pages/dashboard/LaunchFuture')));
const ClientRegister = Loadable(lazy(() => import('../pages/dashboard/ClientRegister')));
const MyKpis = Loadable(lazy(() => import('../pages/dashboard/MyKpis')));
const OpenData = Loadable(lazy(() => import('../pages/dashboard/OpenData')));
const OpenDataClientRegister = Loadable(lazy(() => import('../pages/dashboard/OpenDataClientRegister')));
const OriginationClientRegister = Loadable(lazy(() => import('../pages/dashboard/OriginationClientRegister')));
const OriginationOperations = Loadable(lazy(() => import('../pages/dashboard/OriginationOperations')));
const OriginationDetail = Loadable(lazy(() => import('../pages/dashboard/OriginationDetail')));
const QuoteOperations = Loadable(lazy(() => import('../pages/dashboard/QuoteOperations')));
const OwnOperations = Loadable(lazy(() => import('../pages/dashboard/OwnOperations')));
const ContractDetail = Loadable(lazy(() => import('../pages/dashboard/ContractDetail')));
const MyReceivables = Loadable(lazy(() => import('../pages/dashboard/MyReceivables')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
// Cotacoes
const LoanRegisterValue = Loadable(lazy(() => import('../pages/loan-quote/LoanRegisterValue')));
const LoanRegisterOwner = Loadable(lazy(() => import('../pages/loan-quote/LoanRegisterOwner')));
const LoanRegisterCompany = Loadable(lazy(() => import('../pages/loan-quote/LoanRegisterCompany')));
const LoanRegisterOpenData = Loadable(lazy(() => import('../pages/loan-quote/LoanRegisterOpenData')));
const LoanRegisterConfirmation = Loadable(lazy(() => import('../pages/loan-quote/LoanRegisterConfirmation')));
const LoanRegisterFinal = Loadable(lazy(() => import('../pages/loan-quote/LoanRegisterFinal')));
// Pages
// const Contact = Loadable(lazy(() => import('../pages/Contact')));
// const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
