import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';

const sidebarConfigOriginators = [
  {
    subheader: 'Originação',
    items: [
      {
        title: 'Operações',
        path: PATH_DASHBOARD.general.originationoperations,
        icon: <SwapHorizRoundedIcon />
      },
      {
        title: 'Clientes',
        path: PATH_DASHBOARD.general.originationclientregister,
        icon: <GroupRoundedIcon />
      }
    ]
  }
];

export default sidebarConfigOriginators;
