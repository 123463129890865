import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as BrandLogo } from '../assets/images/lend_logo.svg';
import { ReactComponent as DefaultLogo } from '../assets/images/default_logo.svg';

Logo.propTypes = {
  sx: PropTypes.object,
  dark: PropTypes.bool
};

export default function Logo({ sx, dark = true }) {
  return (
    <Box sx={{ width: 200, height: 50, ...sx }}>
      {dark ? <DefaultLogo width="100%" height="100%" /> : <BrandLogo width="100%" height="100%" />}
    </Box>
  );
}
