// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_COTACOES = '/cotacoes';

// ----------------------------------------------------------------------

export const PATH_COTACOES = {
  root: ROOTS_COTACOES,
  valor: path(ROOTS_COTACOES, '/valor'),
  socios: path(ROOTS_COTACOES, '/socios'),
  empresa: path(ROOTS_COTACOES, '/empresa'),
  openData: path(ROOTS_COTACOES, '/open-data'),
  confirmacao: path(ROOTS_COTACOES, '/confirmacao'),
  final: path(ROOTS_COTACOES, '/final')
};

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  // openDataRegister: path(ROOTS_AUTH, '/open-data-register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  changePassword: path(ROOTS_AUTH, '/change-password'),
  verify: (name) => `${path(ROOTS_AUTH, '/verify')}?name=${name}`,
  openDataRegister: (partnerId) => `${path(ROOTS_AUTH, '/open-data-register')}?partnerId=${partnerId}`,
  openDataRegisterCieloFinal: (state, code) =>
    `${path(ROOTS_AUTH, '/open-data-register-cielo-final')}/?state=${state}&code=${code}`,
  externalOriginationDetail: (originationId) =>
    `${path(ROOTS_AUTH, '/external-origination-detail')}?origination=${originationId}`,
  externalClientOriginationRegisterValue: (partnerId) =>
    `${path(ROOTS_AUTH, '/external-client-origination-register-value')}?partnerId=${partnerId}`,
  externalClientOriginationRegisterOwner: (partnerId) =>
    `${path(ROOTS_AUTH, '/external-client-origination-register-owner')}?partnerId=${partnerId}`,
  externalClientOriginationRegisterCompany: (partnerId) =>
    `${path(ROOTS_AUTH, '/external-client-origination-register-company')}?partnerId=${partnerId}`,
  externalClientOriginationRegisterOpenData: (partnerId) =>
    `${path(ROOTS_AUTH, '/external-client-origination-register-opendata')}?partnerId=${partnerId}`,
  externalClientOriginationRegisterConfirmation: (partnerId) =>
    `${path(ROOTS_AUTH, '/external-client-origination-register-confirmation')}?partnerId=${partnerId}`,
  externalClientOriginationRegisterFinal: (partnerId) =>
    `${path(ROOTS_AUTH, '/external-client-origination-register-final')}?partnerId=${partnerId}`
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  payment: '/payment',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    charge: path(ROOTS_DASHBOARD, '/charge'),
    collateralapproval: path(ROOTS_DASHBOARD, '/collateralapproval'),
    opslaunch: path(ROOTS_DASHBOARD, '/opslaunch'),
    scheduleconsult: path(ROOTS_DASHBOARD, '/scheduleconsult'),
    launchfuture: path(ROOTS_DASHBOARD, '/launchfuture'),
    clientregister: path(ROOTS_DASHBOARD, '/clientregister'),
    mykpis: path(ROOTS_DASHBOARD, '/mykpis'),
    ownoperations: path(ROOTS_DASHBOARD, '/ownoperations'),
    contractDetail: (contractId) => `${path(ROOTS_DASHBOARD, '/contractdetail')}?contract=${contractId}`,
    myreceivables: path(ROOTS_DASHBOARD, '/myreceivables'),
    dataclientregister: path(ROOTS_DASHBOARD, '/dataclientregister'),
    originationclientregister: path(ROOTS_DASHBOARD, '/originationclientregister'),
    originationoperations: path(ROOTS_DASHBOARD, '/originationoperations'),
    originationdetail: (originationId) => `${path(ROOTS_DASHBOARD, '/originationdetail')}?origination=${originationId}`,
    quoteoperations: path(ROOTS_DASHBOARD, '/quoteoperations'),
    opendata: path(ROOTS_DASHBOARD, '/opendata')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
