import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// material
import { Drawer } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import { useSelector } from '../../redux/store';
// routes
// components
import { MHidden } from '../../components/@material-extend';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
//
import sidebarConfigOpenData from './SidebarConfigOpenData';
import sidebarConfigOriginators from './SidebarConfigOriginators';
import sidebarConfigRegister from './SidebarConfigRegister';
import { useScopes } from '../../hooks/useScopes';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { collapseClick, collapseHover, onHoverEnter, onHoverLeave } = useCollapseDrawer();

  const { pathname } = useLocation();
  const { partnerInfo } = useSelector((state) => state.partner);

  const { filteredOptions } = useScopes({
    register: sidebarConfigRegister,
    openData: sidebarConfigOpenData,
    originators: sidebarConfigOriginators
  });

  const registerOptions = filteredOptions.register;
  const openDataOptions = filteredOptions.openData;
  const originatorsOptions = filteredOptions.originators;

  const registerFunc = (partnerInfo) => {
    if (partnerInfo.partnerName === undefined) {
      return false;
    }
    if (partnerInfo.productList.includes('Lender')) {
      return true;
    }
    return false;
  };

  const openDataFunc = (partnerInfo) => {
    if (partnerInfo.partnerName === undefined) {
      return false;
    }
    if (partnerInfo.productList.includes('OpenData')) {
      return true;
    }
    return false;
  };

  const originatorsFunc = (partnerInfo) => {
    if (partnerInfo.partnerName === undefined) {
      return false;
    }
    if (partnerInfo.productList.includes('Originator')) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      {registerFunc(partnerInfo) && !!registerOptions?.[0]?.items.length && (
        <NavSection navConfig={registerOptions} isShow />
      )}
      {openDataFunc(partnerInfo) && !!openDataOptions?.[0]?.items.length && (
        <NavSection navConfig={openDataOptions} isShow />
      )}
      {originatorsFunc(partnerInfo) && !!originatorsOptions?.[0]?.items.length && (
        <NavSection navConfig={originatorsOptions} isShow />
      )}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              position: 'absolute',
              width: DRAWER_WIDTH,
              borderRight: 0,
              bgcolor: 'background.default',
              ...(collapseHover && {
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
