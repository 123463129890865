import packageJson from '../package.json';

export const cognitoConfig = {
  userPoolId: '',
  clientId: ''
};

export const mapConfig = '';

export const apiConfig = {
  baseUrl: process.env?.REACT_APP_API_URL || '',
  minAge: 0
};

export const config = {
  COOKIE_NAME: `${process.env.REACT_APP_COOKIE_NAME}${packageJson.version}`,
  CONNECTION: process.env.REACT_APP_CONNECTION,
  DOMAIN: process.env.REACT_APP_DOMAIN,
  AUDIENCE: process.env.REACT_APP_AUDIENCE,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  CALLBACK_URL: process.env.REACT_APP_CALLBACK_URL,
  GA_MEASUREMENT_ID: process.env.REACT_APP_GA_MEASUREMENT_ID,
  GTM_MEASUREMENT_ID: process.env.REACT_APP_GTM_MEASUREMENT_ID
};
