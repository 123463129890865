/* eslint-disable no-plusplus */
export function validateCpf(cpf) {
  if (!cpf || cpf.length !== 11) {
    return false;
  }
  // eslint-disable-next-line default-case
  switch (cpf) {
    case '00000000000':
    case '11111111111':
    case '22222222222':
    case '33333333333':
    case '44444444444':
    case '55555555555':
    case '66666666666':
    case '77777777777':
    case '88888888888':
    case '99999999999':
      return false;
  }

  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i), 10) * (10 - i);
  }
  let remain = (sum * 10) % 11;
  const firstDigit = remain > 9 ? 0 : remain;

  if (parseInt(cpf.charAt(9), 10) !== firstDigit) {
    return false;
  }

  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i), 10) * (11 - i);
  }
  remain = (sum * 10) % 11;
  const secondDigit = remain > 9 ? 0 : remain;

  return parseInt(cpf.charAt(10), 10) === secondDigit;
}

export function validateCnpj(cnpj) {
  if (!cnpj || cnpj.length !== 14) {
    return false;
  }
  // eslint-disable-next-line default-case
  switch (cnpj) {
    case '00000000000000':
    case '11111111111111':
    case '22222222222222':
    case '33333333333333':
    case '44444444444444':
    case '55555555555555':
    case '66666666666666':
    case '77777777777777':
    case '88888888888888':
    case '99999999999999':
      return false;
  }

  let sum = 0;
  let pos = 5;
  for (let i = 0; i < 12; i++) {
    sum += parseInt(cnpj.charAt(i), 10) * pos--;
    pos = pos < 2 ? 9 : pos;
  }
  let remain = sum % 11;
  const firstDigit = remain < 2 ? 0 : 11 - remain;

  if (parseInt(cnpj.charAt(12), 10) !== firstDigit) {
    return false;
  }

  sum = 0;
  pos = 6;
  for (let i = 0; i < 13; i++) {
    sum += parseInt(cnpj.charAt(i), 10) * pos--;
    pos = pos < 2 ? 9 : pos;
  }
  remain = sum % 11;
  const secondDigit = remain < 2 ? 0 : 11 - remain;

  return parseInt(cnpj.charAt(13), 10) === secondDigit;
}

export const defaultRules = [
  {
    id: 'size',
    required: true,
    test: (password) => password.length >= 8,
    message: 'Possui 8 caracteres ou mais'
  },
  {
    id: 'uppercase',
    required: false,
    test: (password) => /[A-Z]/.test(password),
    message: 'Possui 1 letra maiúscula ou mais'
  },
  {
    id: 'lowercase',
    required: false,
    test: (password) => /[a-z]/.test(password),
    message: 'Possui 1 letra minúscula ou mais'
  },
  {
    id: 'number',
    required: false,
    test: (password) => /\d/.test(password),
    message: 'Possui 1 número ou mais'
  },
  {
    id: 'special',
    required: false,
    test: (password) => /[!@#$%^&*(),.?":{}|<>+]/.test(password),
    message: 'Possui 1 caracter especial ou mais (!@#$%^&*(),.?":{}|<>+)'
  }
];

export const validatePassword = ({ password = '', rules = defaultRules, minOptionalRulesCovered = 3 }) => {
  const testedRules = rules.reduce(
    (acc, rule) => [
      ...acc,
      {
        ...rule,
        covered: rule.test(password)
      }
    ],
    []
  );

  const requiredRulesCovered = testedRules.filter((rule) => rule.required).every((rule) => rule.covered);

  const optionalRulesCovered =
    testedRules.reduce((acc, curr) => (!curr.required && curr.covered ? (acc += 1) : acc), 0) >=
    minOptionalRulesCovered;

  return {
    testedRules,
    pass: requiredRulesCovered && optionalRulesCovered
  };
};
