import { createSlice } from '@reduxjs/toolkit';
// utils
import axios, { CancelToken } from '../../utils/axios';
import { addHours, fDate } from '../../utils/formatTime';
import { parseLambdaDate } from '../../utils/parseTime';
// ----------------------------------------------------------------------

/**
 * @typedef LaunchOperation
 * @property {'warranty' | 'anticipation'} typeOperation
 * @property {[]} selectedReceivables
 * @property {string} cnpj
 * @property {string} taxIdBeneficiary
 * @property {string} bank
 * @property {string} branch
 * @property {string} accountNumber
 * @property {string} accountDigit
 * @property {string} accountType
 */

let source = CancelToken.source();

const initialState = {
  isLoading: false,
  isLoadingCnpj: false,
  error: false,
  name: '',
  cnpj: '',
  createdAt: '',
  cnpjSchedule: '',
  notifications: null,
  usersByPartners: [],
  receivables: [],
  receivablesFull: [],
  receivablesLocked: [],
  receivablesAll: [],
  ownOperations: [],
  openDataCompany: [],
  openData: [],
  openDataSale: [],
  partnerInfo: [],
  cnpjInfo: '',
  foundationDate: '',
  loanOperations: [],
  loanOperationsDetail: [],
  loanInfo: [],
  fantasyName: '',
  partnerInfoById: [],
  contractDetail: [],
  receivablesDetail: [],
  myReceivables: [],
  company: [],
  kpis: []
};

const slice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    // START LOADING
    getUsersByPartnerSuccess(state, action) {
      state.isLoading = false;
      state.usersByPartners = action.payload.usersByPartners;
    },

    getKpisSucess(state, action) {
      state.isLoading = false;
      state.kpis = action.payload.kpis;
    },

    getOwnOperationsSucess(state, action) {
      state.isLoading = false;
      state.ownOperations = action.payload.ownOperations;
    },

    getOpenDataSuccess(state, action) {
      state.isLoading = false;
      state.openData = action.payload.openData;
    },

    getOpenDataCompanySuccess(state, action) {
      state.isLoading = false;
      state.openDataCompany = action.payload.openDataCompany;
    },

    getPartnerInfoSuccess(state, action) {
      state.isLoading = false;
      state.partnerInfo = action.payload.partnerInfo;
    },

    getCnpjInfoSuccess(state, action) {
      state.isLoadingCnpj = false;
      state.cnpjInfo = action.payload.cnpjInfo;
      state.foundationDate = action.payload.foundationDate;
      state.fantasyName = action.payload.fantasyName;
    },

    getPartnerInfoByIdSuccess(state, action) {
      state.isLoading = false;
      state.partnerInfoById = action.payload.partnerInfoById;
    },

    getContractDetailSuccess(state, action) {
      state.isLoading = false;
      state.contractDetail = action.payload.contractDetail;
      state.receivablesDetail = action.payload.receivablesDetail;
    },

    clearContractDetail(state) {
      state.contractDetail = [];
      state.receivablesDetail = [];
    },

    getMyReceivablesSuccess(state, action) {
      state.isLoading = false;
      state.myReceivables = action.payload.myReceivables;
    },

    getLoanOperationsSuccess(state, action) {
      state.isLoading = false;
      state.loanOperations = action.payload.loanOperations;
    },

    getOpenDataSaleSuccess(state, action) {
      state.isLoading = false;
      state.openDataSale = action.payload.openDataSale;
    },

    getLoanOperationsDetailSuccess(state, action) {
      state.isLoading = false;
      state.loanOperationsDetail = action.payload.loanOperationsDetail;
      state.loanInfo = action.payload.loanInfo;
    },

    createCompanySuccess(state) {
      state.isLoading = false;
    },

    cancelContractSuccess(state, action) {
      state.isLoading = false;
      state.ownOperations = state.ownOperations.map((item) => {
        if (item.id === action.payload.contractId) {
          return {
            ...item,
            status: 'Cancelling'
          };
        }
        return item;
      });
    },

    createLoanSuccess(state) {
      state.isLoading = false;
    },

    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startLoadingCnpj(state) {
      state.isLoadingCnpj = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    startLockingReceivable(state) {
      state.isLockingReceivables = true;
    },
    lockingSucess(state) {
      state.isLockingReceivables = false;
    },

    lockingError(state) {
      state.isLockingReceivables = false;
    },

    startRegisterUserOpenData(state) {
      state.isLoading = false;
    },

    registerUserOpenDataSuccess(state) {
      state.isLoading = false;
    },

    registerUserOpenDataError(state) {
      state.isLoading = false;
    },

    startRegisterCompanyOrigination(state) {
      state.isLoading = false;
    },

    registerCompanyOriginationSuccess(state) {
      state.isLoading = false;
    },

    registerCompanyOriginationError(state) {
      state.isLoading = false;
    },

    getScheduleByUserSuccess(state, action) {
      state.isLoading = false;
      state.receivables = action.payload.receivables;
      state.receivablesLocked = action.payload.receivablesLocked;
      state.receivablesAll = action.payload.receivablesAll;
      state.cnpjSchedule = action.payload.cnpjSchedule;
    },

    clearScheduleByUser(state) {
      state.receivables = [];
      state.receivablesLocked = [];
      state.receivablesAll = [];
      state.cnpjSchedule = '';
    },

    getScheduleByUserFullSuccess(state, action) {
      state.isLoading = false;
      state.receivablesFull = action.payload.receivablesFull;
    },

    clearScheduleByUserFull(state) {
      state.receivablesFull = [];
    },

    selectedReceivables(state, actions) {
      state.selected = actions.payload;
    },

    updateReceivablesAll(state, action) {
      const newReceivablesAll = state.receivablesAll.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            receivableValue: action.payload.value
          };
        }

        return item;
      });

      state.receivablesAll = newReceivablesAll;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { selectedReceivables } = slice.actions;

// ----------------------------------------------------------------------

export function getUsersByPartner(relationship) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/partner-portal/company?Status=Active&Relationship=${relationship}`, {
        mode: 'no-cors'
      });
      const mapped = response.data.items
        .filter((c) => c.status === 'Active')
        .map((c, i) => ({
          id: i,
          cnpj: c.company.taxIdentification,
          name: c.company.name,
          relationship: c.relationship,
          status: c.status,
          createdAt: c.createdAt,
          optInType: c.optInType
        }));
      dispatch(
        slice.actions.getUsersByPartnerSuccess({
          usersByPartners: mapped
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearScheduleByUser() {
  return async (dispatch) => {
    try {
      source.cancel();
      dispatch(slice.actions.clearScheduleByUser());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getScheduleByUser(cnpj) {
  return async (dispatch) => {
    clearScheduleByUser();
    dispatch(slice.actions.startLoading());
    const cnpjAux = cnpj;
    source = CancelToken.source();
    try {
      const response = await axios.get(`/partner-portal/schedule/${cnpj}`, {
        mode: 'no-cors',
        cancelToken: source.token
      });
      const mapped = response.data.schedule.operable.map((v, i) => ({
        id: i,
        selected: false,
        PaymentScheme: v.paymentScheme,
        Debtor: v.debtor,
        expectedDate: v.expectedDate,
        liquidationDate: v.expectedDate,
        receivableValue: Number.parseFloat(v.valueAvailable) / 100,
        receivableOriginalValue: Number.parseFloat(v.valueAvailable) / 100
      }));
      const mappedLocked = response.data.schedule.windowLocked.map((v, i) => ({
        id: i,
        selected: false,
        PaymentScheme: v.paymentScheme,
        Debtor: v.debtor,
        expectedDate: v.expectedDate,
        liquidationDate: v.expectedDate,
        receivableValue: Number.parseFloat(v.valueAvailable) / 100,
        receivableOriginalValue: Number.parseFloat(v.valueAvailable) / 100
      }));
      dispatch(
        slice.actions.getScheduleByUserSuccess({
          receivables: mapped,
          receivablesLocked: mappedLocked,
          receivablesAll: [...mappedLocked, ...mapped],
          cnpjSchedule: cnpjAux
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.clearScheduleByUser());
    }
  };
}

export function clearScheduleByUserFull() {
  return async (dispatch) => {
    try {
      source.cancel();
      dispatch(slice.actions.clearScheduleByUserFull());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getScheduleByUserFull(cnpj) {
  return async (dispatch) => {
    clearScheduleByUserFull();
    dispatch(slice.actions.startLoading());
    source = CancelToken.source();
    try {
      const response = await axios.get(`/partner-portal/schedule/${cnpj}/full`, {
        mode: 'no-cors',
        cancelToken: source.token
      });
      const mapped = response.data
        .filter((v) => addHours(parseLambdaDate(v.expectedDate), 4) > new Date())
        .map((v, i) => ({
          id: i,
          PaymentScheme: v.paymentScheme,
          Debtor: v.debtor,
          expectedDate: v.expectedDate,
          liquidationDate: parseLambdaDate(v.expectedDate),
          valueTotal: Number.parseFloat(v.valueTotal),
          valueAvailable: Number.parseFloat(v.valueAvailable),
          valueUnavailable: Number.parseFloat(v.valueUnavailable)
        }));
      dispatch(
        slice.actions.getScheduleByUserFullSuccess({
          receivablesFull: mapped
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.clearScheduleByUserFull());
    }
  };
}

/** @param {LaunchOperation} */
export function lockReceivablesOperation({
  typeOperation,
  cnpj,
  selectedReceivables,
  taxIdBeneficiary,
  bank,
  branch,
  accountNumber,
  accountDigit,
  accountType
}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLockingReceivable());

    try {
      const date = fDate(new Date());
      const formattedDate = `${date.substring(6, 10)}-${date.substring(3, 5)}-${date.substring(0, 2)}`;

      const dataContract = {
        signatureDate: formattedDate,
        dueDate: formattedDate,
        receivableList: selectedReceivables.map((v) => ({
          paymentScheme: v.PaymentScheme,
          debtor: v.Debtor,
          expectedDate: v.liquidationDate,
          yourValue: Number(Number(v.receivableValue * 100).toFixed(0))
        })),
        bankAccount: {
          ispb: bank,
          branch,
          taxIdentification: taxIdBeneficiary,
          account: accountNumber,
          accountDigit,
          accountType
        }
      };

      await axios.post(`/partner-portal/contract/${typeOperation}?taxIdentification=${cnpj}`, dataContract, {
        mode: 'no-cors'
      });

      dispatch(slice.actions.lockingSucess());

      await dispatch(getScheduleByUser(cnpj));
    } catch (error) {
      dispatch(slice.actions.lockingError());
      throw Error(error.message);
    }
  };
}

export function lockReceivablesWarranty(
  selectedReceivables,
  cnpj,
  taxIdBeneficiary,
  bank,
  branch,
  accountNumber,
  accountDigit,
  accountType
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLockingReceivable());
    try {
      const lockReceivablesWarranty = {
        signatureDate: `${fDate(new Date()).substring(6, 10)}-${fDate(new Date()).substring(3, 5)}-${fDate(
          new Date()
        ).substring(0, 2)}`,
        dueDate: `${fDate(new Date()).substring(6, 10)}-${fDate(new Date()).substring(3, 5)}-${fDate(
          new Date()
        ).substring(0, 2)}`,
        receivableList: selectedReceivables.map((v) => ({
          paymentScheme: v.PaymentScheme,
          debtor: v.Debtor,
          expectedDate: v.liquidationDate,
          yourValue: Number(Number(v.receivableValue * 100).toFixed(0))
        })),
        bankAccount: {
          ispb: bank,
          branch,
          taxIdentification: taxIdBeneficiary,
          account: accountNumber,
          accountDigit,
          accountType
        }
      };
      await axios.post(`/partner-portal/contract/warranty?taxIdentification=${cnpj}`, lockReceivablesWarranty, {
        mode: 'no-cors'
      });
      dispatch(slice.actions.lockingSucess());
      await dispatch(getScheduleByUser(cnpj));
    } catch (error) {
      dispatch(slice.actions.lockingError());
      throw Error(error.message);
    }
  };
}

export function lockReceivablesSmoke(
  cnpj,
  amount,
  debtors,
  paymentScheme,
  divisionMethod,
  receivableValue,
  initialDate,
  endDate,
  taxIdBeneficiary,
  bank,
  branch,
  accountNumber,
  accountDigit,
  accountType
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLockingReceivable());
    try {
      const lockReceivablesSmoke = {
        signatureDate: `${fDate(new Date()).substring(6, 10)}-${fDate(new Date()).substring(3, 5)}-${fDate(
          new Date()
        ).substring(0, 2)}`,
        dueDate: `${fDate(new Date()).substring(6, 10)}-${fDate(new Date()).substring(3, 5)}-${fDate(
          new Date()
        ).substring(0, 2)}`,
        bankAccount: {
          ispb: bank,
          branch,
          taxIdentification: taxIdBeneficiary,
          account: accountNumber,
          accountDigit,
          accountType
        },
        smoke: {
          valueOwed: amount,
          debtorList: debtors.map((v) => [v.value]).flat(),
          paymentSchemeList: paymentScheme.map((v) => [v.value]).flat(),
          startDate: initialDate,
          endDate,
          receivableValueType: divisionMethod,
          receivableValue
        }
      };
      await axios.post(`/partner-portal/contract/smoke?taxIdentification=${cnpj}`, lockReceivablesSmoke, {
        mode: 'no-cors'
      });
      dispatch(slice.actions.lockingSucess());
    } catch (error) {
      dispatch(slice.actions.lockingError());
      throw Error(error.message);
    }
  };
}

export function createCompany(cnpj, express) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const company = {
        taxIdentification: cnpj
      };
      await axios.post(`/partner-portal/company/lender?express=${express}`, company, {
        mode: 'no-cors'
      });
      dispatch(slice.actions.createCompanySuccess());
    } catch (error) {
      dispatch(slice.actions.hasError());
      throw Error(error.message);
    }
  };
}

export function cancelContract(contractId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.patch(`/partner-portal/contract/${contractId}/cancel`);
      dispatch(slice.actions.cancelContractSuccess({ contractId }));
    } catch (error) {
      dispatch(slice.actions.hasError());
      throw Error(error.message);
    }
  };
}

export function optoutCompany(cnpj) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.patch(`/partner-portal/company/lender/opt-out?taxIdentification=${cnpj}`);
      dispatch(slice.actions.createCompanySuccess());
    } catch (error) {
      dispatch(slice.actions.hasError());
      throw Error(error.message);
    }
  };
}

export function getKpis() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/partner-portal/schedule/potential`, {
        mode: 'no-cors'
      });
      const mapped = response.data.items.map((c, i) => ({
        id: i,
        name: c.company.name,
        cnpj_cliente: c.company.taxIdentification,
        adquirente: c.debtor.name,
        valor_livre: c.availableValue,
        receita_potencial: c.potentialRevenue,
        contratos_total: c.contractedTotal,
        operado_parceiro: c.contractedPartner,
        operado_outros: c.contractedOthers
      }));
      dispatch(
        slice.actions.getKpisSucess({
          kpis: mapped
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOwnOperations() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/partner-portal/contract/`, {
        mode: 'no-cors'
      });
      const mapped = response.data.items.map((c) => ({
        id: c.id,
        type: c.type,
        status: c.status,
        creationDate: c.createdAt,
        client: c.company.taxIdentification,
        signatureDate: c.signatureDate,
        value: c.receivableList?.reduce((a, b) => a + b.ourValue, 0) ?? 0
      }));
      dispatch(
        slice.actions.getOwnOperationsSucess({
          ownOperations: mapped
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOpenData() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/partner-portal/open-data/finance`, {
        mode: 'no-cors'
      });
      const mapped = response.data.items
        .filter((c) => c.status !== 'Canceled')
        .map((c, i) => ({
          id: i,
          client: c.access.company.taxIdentification,
          debtor: c.access.acquirer.name,
          exercise: c.exerciseDate,
          type: c.type,
          captureType: c.captureType,
          status: c.status,
          value: c.amount * 100,
          mdrValue: c.amountMDR * 100,
          mdrPercentage: c.amountMDR / c.amount,
          flexValue: c.amountFlex * 100
        }));
      dispatch(
        slice.actions.getOpenDataSuccess({
          openData: mapped
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getContractDetail(contractId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/partner-portal/contract/${contractId}`, {
        mode: 'no-cors'
      });
      const mapped = {
        id_contract: response.data.contract.id,
        creationDate: response.data.contract.createdAt,
        client: response.data.contract.company.taxIdentification,
        beneficiaryTaxId: response.data.contract.bankAccount.taxIdentification,
        ispb: response.data.contract.bankAccount.ispb,
        branch: response.data.contract.bankAccount.branch,
        account: response.data.contract.bankAccount.account,
        accountDigit: response.data.contract.bankAccount.accountDigit,
        accountType: response.data.contract.bankAccount.accountType,
        type: response.data.contract.type,
        status: response.data.contract.status,
        smokeDetail: response.data.contract.smoke,
        value: response.data.contract.receivableList?.reduce((a, b) => a + b.ourValue, 0) ?? 0
      };
      const receivablesmapped = response.data.contract.receivableList.map((c) => ({
        id: c.id,
        status: c.status,
        debtor: c.debtor,
        paymentScheme: c.paymentScheme,
        expectedDate: c.expectedDate,
        value: c.ourValue
      }));
      dispatch(
        slice.actions.getContractDetailSuccess({
          contractDetail: mapped,
          receivablesDetail: receivablesmapped
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearContractDetail() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.clearContractDetail());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMyReceivables() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/partner-portal/contract`, {
        mode: 'no-cors'
      });
      const mapped = response.data.items.map((c) => ({
        anticipationList: c.receivableList.map((v) => ({
          id_ur: v.id,
          expectedDate: v.expectedDate,
          settledDate: v.settledDate,
          debtor: v.debtor,
          paymentScheme: v.paymentScheme,
          ourValue: v.ourValue,
          settledValue: v.settledValue,
          status_ur: v.status,
          type_ur: c.type,
          id_contract: c.id,
          creationDate: c.createdAt,
          beneficiaryTaxId: c.bankAccount.taxIdentification,
          ispb: c.bankAccount.ispb,
          branch: c.bankAccount.branch,
          account: c.bankAccount.account,
          accountDigit: c.bankAccount.accountDigit,
          accountType: c.bankAccount.accountType,
          client: c.company.taxIdentification
        }))
      }));
      const result = mapped
        .flatMap(Object.values)
        .flatMap(Object.values)
        .sort((a, b) => (a.expectedDate > b.expectedDate ? 1 : -1));
      const resultFinal = result.map((t, z) => ({
        id: z,
        selected: false,
        id_ur: t.id_ur,
        expectedDate: t.expectedDate,
        settledDate: t.settledDate,
        debtor: t.debtor,
        paymentScheme: t.paymentScheme,
        ourValue: Number.parseFloat(t.ourValue),
        settledValue: Number.parseFloat(t.settledValue),
        status_ur: t.status_ur,
        type_ur: t.type_ur,
        id_contract: t.id_contract,
        creationDate: t.creationDate,
        beneficiaryTaxId: t.beneficiaryTaxId,
        ispb: t.ispb,
        branch: t.branch,
        account: t.account,
        accountDigit: t.accountDigit,
        accountType: t.accountType,
        client: t.client
      }));
      dispatch(
        slice.actions.getMyReceivablesSuccess({
          myReceivables: resultFinal
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPartnerInfo() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const response = await axios.get(`/partner-portal/user`);
    const mapped = {
      partnerId: response.data.partner.id,
      partnerName: response.data.partner.nickname,
      partnerFullName: response.data.partner.name,
      productList: response.data.partner.productList
    };
    dispatch(
      slice.actions.getPartnerInfoSuccess({
        partnerInfo: mapped
      })
    );
  };
}

export function getPartnerInfoById(partnerId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const response = await axios.get(`/public/partner/${partnerId}`);
    const mapped = {
      partnerId: response.data.partner.taxIdentification,
      partnerName: response.data.partner.nickname
    };
    dispatch(
      slice.actions.getPartnerInfoByIdSuccess({
        partnerInfoById: mapped
      })
    );
  };
}

export function registerUserOpenData(acquirer, clientId, cpfCnpj, key1, key2, partnerId) {
  return async (dispatch) => {
    dispatch(slice.actions.startRegisterUserOpenData());

    try {
      const body = {
        acquirerId: acquirer,
        clientId,
        key1,
        key2
      };
      await axios.post(`/public/open-data?partnerId=${partnerId}&companyTaxIdentification=${cpfCnpj}`, body, {
        mode: 'no-cors'
      });
      dispatch(slice.actions.registerUserOpenDataSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOpenDataCompany() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/partner-portal/open-data/`, {
        mode: 'no-cors'
      });
      const mapped = response.data.items
        .filter((c) => c.status !== 'Canceled')
        .map((c, i) => ({
          id: i,
          client: c.company.taxIdentification,
          name: c.company.name,
          debtor: c.acquirer.name,
          status: c.status,
          creationData: c.createdAt
        }));
      dispatch(
        slice.actions.getOpenDataCompanySuccess({
          openDataCompany: mapped
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCnpjInfo(cnpj) {
  return async (dispatch) => {
    await fetch(`https://minhareceita.org/${cnpj}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        dispatch(
          slice.actions.getCnpjInfoSuccess({
            cnpjInfo: data.razao_social,
            foundationDate: data.data_inicio_atividade,
            fantasyName: data.nome_fantasia
          })
        );
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error Fetching data', error);
      });
  };
}

export function registerCompanyOrigination(
  signatureDate,
  taxIdentification,
  name,
  fantasyName,
  email,
  phone,
  foundationDate,
  monthlyRevenue,
  ownerTaxId,
  ownerName,
  ownerEmail,
  ownerPhone,
  ownerRelationship
) {
  return async (dispatch) => {
    dispatch(slice.actions.startRegisterCompanyOrigination());
    try {
      const body = {
        taxIdentification,
        name,
        fantasyName,
        email,
        phone,
        foundationDate,
        monthlyRevenue,
        userList: [
          {
            taxIdentification: ownerTaxId,
            name: ownerName,
            email: ownerEmail,
            phone: ownerPhone,
            relationship: ownerRelationship
          }
        ]
      };
      await axios.post(`/partner-portal/company/originator?signatureDate=${signatureDate}`, body, {
        mode: 'no-cors'
      });
      dispatch(slice.actions.registerCompanyOriginationSuccess());
    } catch (error) {
      dispatch(slice.actions.registerCompanyOriginationError());
      throw Error(error.message);
    }
  };
}

export function getLoanOperations() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/partner-portal/loan`, {
        mode: 'no-cors'
      });
      const mapped = response.data.items.map((c) => ({
        idContract: c.id,
        value: c.value,
        rate: c.rate,
        installmentCount: c.installmentCount,
        status: c.status,
        createdAt: c.createdAt,
        totalContracted: c.totalContracted,
        totalCommission: c.totalCommission,
        taxId: c.company.taxIdentification,
        name: c.company.name
      }));
      dispatch(
        slice.actions.getLoanOperationsSuccess({
          loanOperations: mapped
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLoanOperationsDetail(loanId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/partner-portal/loan/${loanId}`, {
        mode: 'no-cors'
      });

      const mapped = response.data.loan.financierList.map((c) => ({
        List: c.offerList.map((v) => ({
          value: v.value,
          rate: v.rate,
          installmentCount: v.installmentCount,
          type: v.type,
          createdAt: v.createdAt,
          financierName: c.financier.nickname,
          status: c.status,
          reason: c.reason,
          externalAddress: c.externalAddress,
          projectedComissionRate: c.projectedComissionRate
        }))
      }));

      const result = mapped.flatMap(Object.values).flatMap(Object.values);
      const resultFinal = result.map((t, z) => ({
        id: z,
        value: t.value,
        rate: t.rate,
        installmentCount: t.installmentCount,
        type: t.type,
        createdAt: t.createdAt,
        financierName: t.financierName,
        status: t.status,
        reason: t.reason,
        externalAddress: t.externalAddress,
        projectedComissionRate: t.projectedComissionRate
      }));
      const loanMapped = {
        idLoan: response.data.loan.id,
        value: response.data.loan.value,
        installmentCount: response.data.loan.installmentCount,
        status: response.data.loan.status,
        createdAt: response.data.loan.createdAt,
        name: response.data.loan.company.name
      };
      dispatch(
        slice.actions.getLoanOperationsDetailSuccess({
          loanOperationsDetail: resultFinal,
          loanInfo: loanMapped
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createLoan(taxIdentification, value, installmentCount) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const body = {
        value,
        installmentCount
      };
      await axios.post(`/partner-portal/loan?taxIdentification=${taxIdentification}`, body, {
        mode: 'no-cors'
      });
      dispatch(slice.actions.createLoanSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError());
      throw Error(error.message);
    }
  };
}

export function registerCompanyOriginationPublic(
  partnerId,
  taxIdentification,
  name,
  fantasyName,
  email,
  phone,
  foundationDate,
  monthlyRevenue,
  ownerTaxId,
  ownerName,
  ownerEmail,
  ownerPhone,
  ownerRelationship
) {
  return async (dispatch) => {
    dispatch(slice.actions.startRegisterCompanyOrigination());
    try {
      const body = {
        taxIdentification,
        name,
        fantasyName,
        email,
        phone,
        foundationDate,
        monthlyRevenue: monthlyRevenue * 100,
        userList: [
          {
            taxIdentification: ownerTaxId,
            name: ownerName,
            email: ownerEmail,
            phone: ownerPhone,
            relationship: ownerRelationship
          }
        ]
      };
      await axios.post(`/public/company/originator?partnerId=${partnerId}`, body, {
        mode: 'no-cors'
      });
      dispatch(slice.actions.registerCompanyOriginationSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createLoanPublic(taxIdentification, partnerId, value, installmentCount) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const body = {
        value: value * 100,
        installmentCount
      };
      await axios.post(`/public/loan?taxIdentification=${taxIdentification}&partnerId=${partnerId}`, body, {
        mode: 'no-cors'
      });
      dispatch(slice.actions.createLoanSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createComboOrigination(
  partnerId,
  cnpj,
  cnpjInfo,
  fantasyName,
  email,
  phone,
  foundationDate,
  revenue,
  cpf,
  name,
  ownerEmail,
  ownerRelationship,
  value,
  installmentCount
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await dispatch(
        registerCompanyOriginationPublic(
          partnerId,
          cnpj,
          cnpjInfo,
          fantasyName,
          email,
          phone,
          foundationDate,
          revenue,
          cpf,
          name,
          ownerEmail,
          phone,
          ownerRelationship
        )
      );
      dispatch(createLoanPublic(cnpj, partnerId, value, installmentCount));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOpenDataSale(
  startDate,
  endDate,
  taxIdentificationList,
  acquirerIdList,
  pageNumber = 1,
  pageSize = 2000
) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    let openDataSale = [];
    let count = pageSize;

    const apiCall = async () => {
      try {
        const body = {
          startDate,
          endDate,
          taxIdentificationList,
          acquirerIdList,
          pageNumber,
          pageSize
        };

        const { data } = await axios.post(`/partner-portal/open-data/sale`, body);

        const formattedData = data.items.map((c) => ({
          type: c.type,
          captureType: c.captureType,
          captureDate: c.captureDate,
          paymentDate: c.paymentDate,
          installment: c.installment,
          installmentTotal: c.installmentTotal,
          amountTotal: c.amountTotal * 100,
          amountMdr: c.amountMDR * 100,
          amountLiquid: c.amountLiquid * 100,
          anticipationTax: c.anticipationTax * 100,
          status: c.status,
          taxId: c.access.company.taxIdentification,
          name: c.access.company.name,
          acquirer: c.access.acquirer.name,
          paymentScheme: c.transaction.brand,
          transactionType: c.transaction.type,
          id: c.transaction.id,
          international: c.transaction.international
        }));

        openDataSale = [...openDataSale, ...formattedData];

        if (data.totalItems > count) {
          pageNumber += 1;
          count += pageSize;
          await apiCall();
        }
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };

    await apiCall();

    dispatch(
      slice.actions.getOpenDataSaleSuccess({
        openDataSale
      })
    );
  };
}

export function updateReceivablesAll({ id, value }) {
  return (dispatch) => {
    dispatch(slice.actions.updateReceivablesAll({ id, value }));
  };
}
