import axios from 'axios';
import { apiConfig } from '../config';

export const { isAxiosError, CancelToken } = axios;

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: apiConfig.baseUrl
});

axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export const setAxiosHeader = (headers) => {
  axiosInstance.defaults.headers = {
    ...axiosInstance.defaults.headers,
    ...headers
  };
};

export default axiosInstance;
