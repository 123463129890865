import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled, useTheme } from '@mui/material/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

import useAuth from '../../hooks/useAuth';
import { getPartnerInfo, getUsersByPartner } from '../../redux/slices/partner';
import { getUserInfo } from '../../redux/slices/user';
import { useDispatch } from '../../redux/store';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserInfo());
      dispatch(getPartnerInfo());
      dispatch(getUsersByPartner('Lender'));
    }
  }, [dispatch, isAuthenticated]);

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />

      <RootStyle
        sx={{
          width: '100%',
          position: 'relative',
          minHeight: 'auto',
          height: 'calc(100vh - 70px)',
          alignSelf: 'flex-end'
        }}
      >
        <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        <MainStyle
          sx={{
            transition: theme.transitions.create('margin', {
              duration: theme.transitions.duration.complex
            }),
            ...(collapseClick && {
              ml: '102px'
            })
          }}
        >
          <Outlet />
        </MainStyle>
      </RootStyle>
    </RootStyle>
  );
}
