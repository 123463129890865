import { createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDateTimeRange } from '../hooks/useDateTimeRange';

const DEFAULT_MESSAGE = 'Serviço indisponível. Esta operação só pode ser realizada em dias úteis, das 09h às 18h45.';

/**
 * @typedef TimeWindowContext
 * @property {boolean} outsideTimeWindow
 * @property {string} messageOutsideTimeWindow
 */

/** @type {React.Context<TimeWindowContext>} */
const TimeWindowContext = createContext({});

export const TimeWindowProvider = ({ children }) => {
  const { insideRange, ref } = useDateTimeRange({ ms: 1 * 1000, startTime: '09:00', endTime: '18:45' });
  const outsideTimeWindow = !insideRange;

  useEffect(
    () => () => {
      ref.current.clearInterval();
    },
    [ref]
  );

  return (
    <TimeWindowContext.Provider value={{ outsideTimeWindow, messageOutsideTimeWindow: DEFAULT_MESSAGE }}>
      {children}
    </TimeWindowContext.Provider>
  );
};

TimeWindowProvider.propTypes = {
  children: PropTypes.node
};

export const useTimeWindow = () => useContext(TimeWindowContext);
