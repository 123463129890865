import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  name: '',
  partnerName: '',
  cnpj: '',
  id: '',
  taxIdentification: '',
  bankAccount: {},
  phone: '',
  email: '',
  address: {},
  represent: {
    name: '',
    address: {}
  },
  notifications: null,
  /**
   * TODO: Use Auth0 Scopes
   *
   * @description
   * - TEMPORARY SOLUTION
   * - Some partners do not have access to all functions (routes) of the application,
   * so this array tells which routes it can't access.
   * - The object is formed by the cnpj as a key and the array with the routes notavailable to them.
   * @example
   * { '39879188000125': ['/dashboard/opslaunch'] }
   */
  blockedFuncs: {
    33814571000119: [
      '/dashboard/opslaunch',
      '/dashboard/ownoperations',
      '/dashboard/launchfuture',
      '/dashboard/myreceivables'
    ],
    31987286000174: [
      '/dashboard/opslaunch',
      '/dashboard/ownoperations',
      '/dashboard/launchfuture',
      '/dashboard/myreceivables'
    ]
  }
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    getUser(state, action) {
      state.isLoading = false;
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.taxIdentification = action.payload.taxIdentification;
      state.phone = action.payload.phone;
      state.email = action.payload.email;
      state.partnerId = action.payload.partnerId;
      state.cnpj = action.payload.cnpj;
      state.partnerName = action.payload.partnerName;
    },

    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    updateUserSuccess(state, action) {
      state.isLoading = false;
      state = action.payload;
    },

    getBankAccountSuccess(state, action) {
      state.isLoading = false;
      state.bankAccount = action.payload;
    },

    updateRepresentSuccess(state, action) {
      state.isLoading = false;
      state.represent = action.payload;
    },

    updateBankAccountSuccess(state, action) {
      state.isLoading = false;
      state.bankAccount = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getUser } = slice.actions;

// ----------------------------------------------------------------------

export function getBankAccount() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/users/bankAccount`);
      dispatch(slice.actions.updateBankAccountSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateAddress(address) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/users/address`, {
        address
      });
      const userResponse = {
        name: response.data.name,
        cnpj: response.data.brIdentifier,
        phone: response.data.phone,
        email: response.data.email,
        address: response.data.address
      };
      dispatch(slice.actions.updateUserSuccess({ userResponse }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw Error(error.message);
    }
  };
}

export function updateRepresent(represent) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await new Promise((resolve) => setTimeout(resolve, 200));
      dispatch(slice.actions.updateRepresentSuccess({ represent }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw Error(error.message);
    }
  };
}

export function updateBankAccount(bankAccount) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/users/bankAccount`, bankAccount, {
        mode: 'no-cors'
      });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw Error(error.message);
    }
  };
}

export function getUserInfo() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const response = await axios.get(`/partner-portal/user`, {
      mode: 'no-cors'
    });
    const user = {
      id: response.data.user.id,
      taxIdentification: response.data.user.taxIdentification,
      name: response.data.user.name,
      email: response.data.user.email,
      phone: response.data.user.phone,
      partnerId: response.data.partner.id,
      cnpj: response.data.partner.taxIdentification,
      partnerName: response.data.partner.name,
      nickName: response.data.partner.nickname
    };
    dispatch(getUser(user));
  };
}
