import { Box, Divider, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Logo from '../../components/Logo';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import Page from '../../components/Page';

import backgroundMD from '../../assets/images/background_md.svg';
import background from '../../assets/images/background.svg';

const RootStyle = styled(Page)(({ theme }) => ({
  height: '100vh',
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 500,
  display: 'flex',
  gap: '50px',
  flexDirection: 'column',
  padding: '16px 40px 0',
  backgroundImage: `url(${backgroundMD})`,
  backgroundSize: 'cover',
  backgroundPosition: 'left',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.up('lg')]: {
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center'
  }
}));

const ContentStyle = styled('div')(() => ({
  maxWidth: 480,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}));

export default function Login() {
  return (
    <RootStyle title="Login | Lend">
      <MHidden width="mdDown">
        <SectionStyle>
          <Box
            sx={({ breakpoints }) => ({
              display: 'flex',
              alignItems: 'center',
              gap: '24px',
              [breakpoints.down('lg')]: {
                '> h5, > hr': {
                  display: 'none'
                }
              }
            })}
          >
            <Logo sx={{ width: '80px' }} dark={false} />
            <Divider orientation="vertical" />
            <Typography color="#FFFFFFDE" variant="h5">
              Recebíveis
            </Typography>
          </Box>

          <Box
            sx={({ breakpoints }) => ({
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              [breakpoints.down('lg')]: {
                display: 'none'
              }
            })}
          >
            <Typography lineHeight="38px" fontStyle="normal" fontWeight="bold" fontSize="34px" color="#E1FEF4">
              Atisbank agora faz parte da plataforma Lend!
            </Typography>
            <Typography variant="subtitle1" color="#FFF">
              Construindo tecnologia juntos para você potencializar seu negócio oferencendo crédito simples e intuitivo.
            </Typography>
          </Box>
        </SectionStyle>
      </MHidden>

      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          padding: '20px'
        }}
      >
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" fontWeight="bold" gutterBottom>
                Acesse sua conta
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>Insira seus dados de login.</Typography>
            </Box>
          </Stack>

          <LoginForm />

          <Typography variant="body2" sx={{ paddingTop: '40px' }}>
            Não possui uma conta? Favor entrar em contato com <br />
            registradora-ops@lend.tech
          </Typography>
        </ContentStyle>
      </Box>
    </RootStyle>
  );
}
