import { Helmet } from 'react-helmet-async';
import { config } from '../config';

// ----------------------------------------------------------------------

export default function GoogleAnalytics() {
  if (config.GA_MEASUREMENT_ID && config.GTM_MEASUREMENT_ID) {
    return (
      <>
        <Helmet>
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_MEASUREMENT_ID}`} />
          <script>
            {`
              window.dataLayer = window.dataLayer || [];

              function gtag() {
                dataLayer.push(arguments);
              }

              gtag('js', new Date());
              gtag('config', '${config.GA_MEASUREMENT_ID}');
            `}
          </script>
          <script>{`(function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer', '${config.GTM_MEASUREMENT_ID}');`}</script>
        </Helmet>
        <noscript>
          <iframe
            title="hue"
            src={`https://www.googletagmanager.com/ns.html?id=${config.GTM_MEASUREMENT_ID}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
      </>
    );
  }
  return <></>;
}
