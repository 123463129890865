import { createContext, useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import auth0 from 'auth0-js';
import { parseCookies, setCookie, destroyCookie } from 'nookies';

import axios, { setAxiosHeader } from '../utils/axios';

import { config } from '../config';

/**
 * @typedef LoginProps
 * @property {string} username
 * @property {string} password
 */

const WebAuth = new auth0.WebAuth({
  domain: config.DOMAIN,
  clientID: config.CLIENT_ID,
  redirectUri: config.CALLBACK_URL,
  responseType: 'id_token token'
});
const Authentication = new auth0.Authentication({
  domain: config.DOMAIN,
  clientID: config.CLIENT_ID,
  redirectUri: config.CALLBACK_URL,
  responseType: 'id_token token'
});

const initialState = {
  isAuthenticated: false,
  loading: true,
  /** @type {auth0.Auth0UserProfile} */
  user: null,
  cnpj: null
};

const handlers = {
  AUTHENTICATE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      loading: false,
      isAuthenticated,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    loading: false,
    user: null
  }),
  RESET: (state, action) => {
    const { cnpj } = action.payload;
    return {
      ...state,
      isAuthenticated: false,
      cnpj
    };
  },
  LOADING: (state, action) => {
    const { loading } = action.payload;

    return { ...state, loading };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'auth0',
  /** @type {(params: LoginProps) => Promise<void>} */
  login: () => Promise.resolve(),
  logout: () => {},
  /** @type {(email?: string) => Promise<void>} */
  changePassword: () => Promise.resolve(),
  register: () => Promise.resolve()
});

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = async () => {
    const { [config.COOKIE_NAME]: token } = parseCookies(undefined);

    return token;
  };

  const saveToken = async (accessToken = '', expiresIn = 7200) => {
    setCookie(undefined, config.COOKIE_NAME, accessToken, {
      path: '/',
      maxAge: expiresIn,
      secure: true, // It's only sent over HTTPS connections.
      sameSite: 'strict' // Restricted to same-site requests.
    });
  };

  const changePassword = useCallback(
    (email = state.user.email) =>
      new Promise((resolve, reject) => {
        WebAuth.changePassword({ connection: config.CONNECTION, email }, (err, result) => {
          if (err) {
            return reject(err);
          }

          return resolve(result);
        });
      }),
    [state.user?.email]
  );

  const register = useCallback(async (cnpj, cpf, fullName, phone, email) => {
    await axios.post(`/admin/user?companyTaxIdentification=${cnpj.replace(/\D/g, '')}`, {
      taxIdentification: cpf.replace(/\D/g, ''),
      name: fullName,
      email,
      phone
    });

    dispatch({
      type: 'RESET',
      payload: { cnpj }
    });
  }, []);

  const login = useCallback(
    ({ username = 'somed-test-user@lend.tech', password = 'dev@Lend123' }) =>
      new Promise((resolve, reject) => {
        WebAuth.login(
          {
            username,
            password,
            realm: config.CONNECTION,
            audience: config.AUDIENCE,
            onRedirecting: (done) => {
              done();
              resolve();
            }
          },
          (error) => {
            reject(error);
          }
        );
      }),
    []
  );

  const logout = useCallback(() => {
    destroyCookie(undefined, config.COOKIE_NAME, { path: '/' });
    setAxiosHeader({ Authorization: '' });

    WebAuth.logout({ returnTo: `${window.location.origin}/auth/login` });
  }, []);

  const getUser = useCallback(
    /** @param {string} accessToken */
    (accessToken) => {
      Authentication.userInfo(accessToken, (err, user) => {
        if (user) {
          setAxiosHeader({ Authorization: `Bearer ${accessToken}` });

          dispatch({
            type: 'AUTHENTICATE',
            payload: { isAuthenticated: true, user }
          });

          return;
        }

        logout();
      });
    },
    [logout]
  );

  const load = useCallback(async () => {
    axios.interceptors.response.use(
      (res) => res,
      (err) => {
        if ([401].includes(err?.response?.status)) {
          logout();
        }

        return Promise.reject((err.response && err.response.data) || 'Something went wrong');
      }
    );

    const token = await getToken();

    if (token) {
      getUser(token);
    } else {
      WebAuth.parseHash(async (err, data) => {
        if (!data && !err) {
          dispatch({
            type: 'LOADING',
            payload: { loading: false }
          });

          return;
        }

        window.location.hash = '';

        if (data) {
          const { accessToken, expiresIn } = data;
          getUser(accessToken, expiresIn);

          await saveToken(accessToken, expiresIn);

          return;
        }

        logout();
      });
    }
  }, [getUser, logout]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <AuthContext.Provider value={{ ...state, login, logout, register, changePassword }}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node
};

export { AuthProvider, AuthContext };
