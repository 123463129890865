import { format, formatDistanceToNow, getTime, parse } from 'date-fns';

// ----------------------------------------------------------------------

function subtractHours(date, hours) {
  date.setHours(date.getHours() - hours);

  return date;
}

export function addHours(date, hours) {
  date.setHours(date.getHours() + hours);

  return date;
}

export function fDateDB(date) {
  if (date) {
    return format(addHours(new Date(date), 3), 'dd/MM/yyyy');
  }
  return '-';
}

export function fDate(date) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function fMonth(date) {
  return format(new Date(`${date}T12:00:00.00`), 'MM/yyyy');
}

export function fDateTime(date) {
  return format(subtractHours(new Date(date), 3), 'dd/MM/yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function fDueDate(dueDate) {
  return new Date(`${dueDate}T12:00:00.00`);
}

export function getCurrentDateTime() {
  return new Intl.DateTimeFormat('pt-BR', {
    timeStyle: 'short',
    dateStyle: 'short',
    timeZone: 'America/Sao_Paulo'
  }).format(new Date());
}

export function isTimeInRange(time, startTime, endTime) {
  // Cria objetos Date para as três horas
  const timeObj = new Date(`2022-01-01T${time}`);
  const startObj = new Date(`2022-01-01T${startTime}`);
  const endObj = new Date(`2022-01-01T${endTime}`);

  // Verifica se a hora está entre o início e o fim do intervalo
  return timeObj >= startObj && timeObj <= endObj;
}

export function isDateTimeInRange(dateTime, startTime, endTime) {
  const [dateString, timeString] = dateTime.split(' ');

  // Cria objeto Date para a data
  const dateObj = parse(dateString, 'dd/MM/yyyy', new Date());
  const day = dateObj.getDay();

  // Verifica se a hora está entre o início e o fim do intervalo e se está em dias úteis
  return isTimeInRange(timeString, startTime, endTime) && day !== 0 && day !== 6;
}
