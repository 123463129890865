import { useEffect, useImperativeHandle, useRef, useState } from 'react';

import { getCurrentDateTime, isDateTimeInRange, isTimeInRange } from '../utils/formatTime';

export const useDateTimeRange = ({ startTime = '09:00', endTime = '19:30', ms = 60 * 1000, isDayOfWeek = true }) => {
  const functionCheck = isDayOfWeek ? isDateTimeInRange : isTimeInRange;

  const [insideRange, setInsideRange] = useState(functionCheck(getCurrentDateTime(), startTime, endTime));

  /** @type {React.MutableRefObject<{ clearInterval: () => void }>} */
  const ref = useRef(null);
  /** @type {React.MutableRefObject<NodeJS.Timer>} */
  const intervalRef = useRef(null);

  // Verifica a hora atual a cada minuto
  intervalRef.current = setInterval(() => {
    setInsideRange(functionCheck(getCurrentDateTime(), startTime, endTime));
  }, ms);

  useEffect(
    () => () => {
      clearInterval(intervalRef?.current);
    },
    []
  );

  useImperativeHandle(ref, () => ({
    clearInterval: () => {
      if (intervalRef?.current) {
        clearInterval(intervalRef.current);
      }
    }
  }));

  return { ref, insideRange };
};
