import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import bankReducer from './slices/bank';
import partnerReducer from './slices/partner';
import receivablesReducer from './slices/receivables';
import userReducer from './slices/user';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  user: userReducer,
  receivables: receivablesReducer,
  bank: bankReducer,
  partner: partnerReducer
});

export { rootPersistConfig, rootReducer };
