/* eslint-disable no-plusplus */
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { parseLambdaDate } from '../../utils/parseTime';
import { updateBalance } from './bank';

const initialState = {
  isLoading: false,
  isLockingReceivables: false,
  error: false,
  canOperate: false,
  tax: 0.0,
  cutRate: 0.0,
  receivables: []
};

const slice = createSlice({
  name: 'receivables',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    startLockingReceivable(state) {
      state.isLockingReceivables = true;
    },

    lockingSucess(state) {
      state.isLockingReceivables = false;
    },

    lockingError(state) {
      state.isLockingReceivables = false;
    },

    getReceivablesSuccess(state, action) {
      state.isLoading = false;
      state.receivables = action.payload.receivables;
      state.canOperate = action.payload.canOperate;
      state.tax = action.payload.tax;
      state.cutRate = action.payload.cutRate;
    },

    selectedReceivables(state, actions) {
      state.selected = actions.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { selectedReceivables } = slice.actions;

// ----------------------------------------------------------------------

export function getReceivables() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/receivables/schedule`, {
        mode: 'no-cors'
      });
      const mapped = response.data.scheduleResponse.schedule.map((v, i) => {
        const liquidationDate = parseLambdaDate(v.ExpectedDate);
        return {
          id: i,
          selected: false,
          PaymentScheme: v.PaymentScheme,
          Debtor: v.Debtor,
          liquidationDate,
          receivableValue: Number.parseFloat(v.OriginalValue),
          disponibleValue: Number.parseFloat(v.NewValue)
        };
      });
      dispatch(
        slice.actions.getReceivablesSuccess({
          receivables: mapped,
          canOperate: response.data.scheduleResponse.canOperate,
          cutRate: response.data.scheduleResponse.tax.cutRate,
          tax: parseFloat(response.data.scheduleResponse.tax.rate) * 100
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function lockReceivales(selectedReceivables) {
  return async (dispatch) => {
    dispatch(slice.actions.startLockingReceivable());
    try {
      const lockReceivables = selectedReceivables.map((v) => ({
        PaymentScheme: v.PaymentScheme,
        Debtor: v.Debtor,
        ExpectedDate: v.liquidationDate,
        DesiredValue: v.disponibleValue
      }));
      await axios.post(`/receivables/contract`, lockReceivables, {
        mode: 'no-cors'
      });
      dispatch(slice.actions.lockingSucess());
      dispatch(getReceivables());
      setTimeout(() => dispatch(updateBalance()), 5000);
    } catch (error) {
      dispatch(slice.actions.lockingError());
      throw Error(error.message);
    }
  };
}
