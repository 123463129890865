import personFill from '@iconify/icons-eva/person-fill';
import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Button, Divider, MenuItem, Typography } from '@mui/material';
// routes
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import { useSelector } from '../../redux/store';
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import MenuPopover from '../../components/MenuPopover';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Minha Conta',
    icon: personFill,
    linkTo: PATH_DASHBOARD.user.account
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { logout } = useAuth();

  const { name, partnerName, email } = useSelector((state) => state.user);

  const isMountedRef = useIsMountedRef();

  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    logout();

    if (isMountedRef.current) {
      handleClose();
    }
  };

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '5px 24px',
          maxWidth: '250px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
          }}
        >
          <Typography
            color="text.primary"
            variant="caption"
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {partnerName}
          </Typography>
          <Typography
            color="text.secondary"
            variant="body1"
            sx={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {name}
          </Typography>
        </Box>

        <ArrowDropDownRoundedIcon
          sx={{
            transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease-in-out'
          }}
        />
      </Button>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Sair
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
