import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

/**
 * @param { {[key: string]: [ { items: [ { path: string } ] } ] } } options
 */
export const useScopes = (options) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { blockedFuncs, cnpj } = useSelector((state) => state.user);

  useEffect(() => {
    if (blockedFuncs[cnpj] && blockedFuncs[cnpj].includes(pathname?.toLowerCase())) {
      navigate('/dashboard/myKpis', { replace: true });
    }
  }, [cnpj, blockedFuncs, pathname, navigate]);

  const getOptions = useCallback(
    (options, paths) =>
      options.map((option) => ({
        ...option,
        items: option.items.filter((item) => !paths.includes(item?.path?.toLowerCase()))
      })),
    []
  );

  const filteredOptions = useMemo(() => {
    if (!cnpj) return Object.keys(options).reduce((acc, curr) => ({ ...acc, [curr]: [] }), {});

    if (blockedFuncs[cnpj]) {
      return Object.entries(options).reduce(
        (acc, [key, value]) => ({ ...acc, [key]: getOptions(value, blockedFuncs[cnpj]) }),
        {}
      );
    }

    return options;
  }, [options, blockedFuncs, cnpj, getOptions]);

  return { filteredOptions };
};
