import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: false,
  balance: 0.0
};

const slice = createSlice({
  name: 'bank',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getBankAccountSuccess(state, action) {
      state.isLoading = false;
      state.bankAccount = action.payload;
    },

    updateBalance(state, action) {
      state.isLoading = false;
      state.balance = action.payload;
    },

    withdrawSuccess(state, action) {
      state.isLoading = false;
      state.balance -= action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { getUser } = slice.actions;

// ----------------------------------------------------------------------

export function updateBalance() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/accounts/balance`);
      dispatch(slice.actions.updateBalance(parseFloat(response.data.balance)));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function withdraw(amount, bankAccount) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(
        `/payment/withdraw`,
        {
          withdrawRequest: {
            amount,
            bankAccount
          }
        },
        {
          mode: 'no-cors'
        }
      );
      dispatch(slice.actions.withdrawSuccess(amount));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw Error(error.message);
    }
  };
}
