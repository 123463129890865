import { addDays } from 'date-fns';
import * as Yup from 'yup';
import { apiConfig } from '../../config';
import { parseDateString } from '../../utils/parseTime';
import { validateCnpj, validateCpf, validatePassword } from '../../utils/validators';

export const emailYup = () => Yup.string().email('Email inválido').required('Campo obrigatório');

export const cpfCnpjYup = () =>
  Yup.string()
    .required('Informe um CNPJ ou CPF')
    .transform((value) => value.replace(/[^\d]/g, ''))
    .test('cpfCnpj-validation', 'CNPJ/CPF não é válido', (value) => validateCnpj(value) || validateCpf(value));

export const cpfYup = () =>
  Yup.string()
    .required('Campo obrigatório')
    .transform((value) => value.replace(/[^\d]/g, ''))
    .test('cpfCnpj-validation', 'CPF não é válido', (value) => validateCpf(value));

export const cnpjYup = () =>
  Yup.string()
    .required('Campo obrigatório')
    .transform((value) => value.replace(/[^\d]/g, ''))
    .test('cpfCnpj-validation', 'CNPJ não é válido', (value) => validateCnpj(value));

export const passwordYup = () =>
  Yup.string().min(6, 'Senha tem que ter no mínimo 6 caracteres').required('Campo obrigatório').matches();

export const incorporationDate = () =>
  Yup.date()
    .transform(parseDateString)
    .max(addDays(new Date(), -apiConfig.minAge), 'Data tem que ser até o dia atual')
    .required('Campo obrigatório');

export const phoneYup = () => Yup.string().max(addDays(new Date(), -apiConfig.minAge));

export const passwordValidateYup = () =>
  Yup.string()
    .required('Campo obrigatório')
    .test('testar-senha', 'Sua senha parece incorreta', (password) => validatePassword({ password }).pass);
