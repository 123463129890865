import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
// material
import { AppBar, Box, Divider, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
import Logo from '../../components/Logo';

const COLLAPSE_WIDTH = 102;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  maxHeight: '56px',
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `100%`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.up('lg')]: {
    padding: '0 25px'
  }
}));

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { isCollapse } = useCollapseDrawer();

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgDown">
          <Logo sx={{ width: '80px' }} />

          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderRightWidth: 1,
              margin: '4px 32px'
            }}
          />

          <Typography color="#00000099" fontSize="24px" lineHeight="24px" letterSpacing="0.18px">
            Recebíveis
          </Typography>
        </MHidden>

        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 5 }}>
          <AccountPopover />
        </Stack>
      </ToolbarStyle>

      <Divider
        sx={{
          borderRightWidth: 1,
          margin: '4px 0'
        }}
      />
    </RootStyle>
  );
}
