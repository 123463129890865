import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';

const sidebarConfigRegister = [
  {
    subheader: 'Registradoras',
    items: [
      {
        title: 'Indicadores',
        path: PATH_DASHBOARD.general.mykpis,
        icon: <TimelineRoundedIcon />
      },
      {
        title: 'Clientes',
        path: PATH_DASHBOARD.general.clientregister,
        icon: <GroupRoundedIcon />
      },
      {
        title: 'Consultar agenda',
        path: PATH_DASHBOARD.general.scheduleconsult,
        icon: <DateRangeRoundedIcon />
      },
      {
        title: 'Lançar operação',
        path: PATH_DASHBOARD.general.opslaunch,
        icon: <PostAddRoundedIcon />
      },
      {
        title: 'Contrato fumaça',
        path: PATH_DASHBOARD.general.launchfuture,
        icon: <EventRoundedIcon />
      },
      {
        title: 'Operações',
        path: PATH_DASHBOARD.general.ownoperations,
        icon: <SwapHorizRoundedIcon />
      },
      {
        title: 'Meus recebíveis',
        path: PATH_DASHBOARD.general.myreceivables,
        icon: <AttachMoneyRoundedIcon />
      }
    ]
  }
];

export default sidebarConfigRegister;
