import { parse, isDate } from 'date-fns';

export function parseLambdaDate(date) {
  return new Date(date);
}

/**
 * Use with Yup
 *
 * @param {*} value
 * @param {*} originalValue
 * @returns
 */
export function parseDateString(value, originalValue) {
  const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'ddMMyyyyy', new Date());

  return parsedDate;
}
